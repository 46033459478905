define('ember-multithread/index', ['exports', 'ember-multithread/-worker-property'], function (exports, _emberMultithreadWorkerProperty) {
  'use strict';

  var _bind = Function.prototype.bind;
  exports.worker = worker;

  function worker() {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return new (_bind.apply(_emberMultithreadWorkerProperty['default'], [null].concat(args)))();
  }
});