define('ember-multithread/-worker-property', ['exports', 'ember', 'ember-multithread/utils'], function (exports, _ember, _emberMultithreadUtils) {
  'use strict';

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i['return']) _i['return']();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError('Invalid attempt to destructure non-iterable instance');
      }
    };
  })();

  exports['default'] = WorkerProperty;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
    } else {
      obj[key] = value;
    }return obj;
  }

  var _Worker = _ember['default'].Object.extend(_defineProperty({
    _fn: null,
    _context: null,
    // throttle rate 16.6667ms => 60 FPS
    _throttle: 16.66666,
    _getWorkerScript: function _getWorkerScript() {
      var throttle = '\n      function throttle(func, wait, options) {\n        var context, args, result;\n        var timeout = null;\n        var previous = 0;\n        if (!options) {\n          options = {};\n        }\n        var later = function() {\n          previous = options.leading === false ? 0 : Date.now();\n          timeout = null;\n          result = func.apply(context, args);\n          if (!timeout) {\n            context = args = null;\n          }\n        };\n        return function() {\n          var now = Date.now();\n          if (!previous && options.leading === false) {\n            previous = now;\n          }\n          var remaining = wait - (now - previous);\n          context = this;\n          args = arguments;\n          if (remaining <= 0 || remaining > wait) {\n            if (timeout) {\n              clearTimeout(timeout);\n              timeout = null;\n            }\n            previous = now;\n            result = func.apply(context, args);\n            if (!timeout) {\n              context = args = null;\n            }\n          } else if (!timeout && options.trailing !== false) {\n            timeout = setTimeout(later, remaining);\n          }\n          return result;\n        };\n      }\n    ';
      var contextProxy = 'function ContextProxy() {\n        this.get = this.getProperties = this.getEach = this.getWithDefault = this.incrementProperty = this.decrementProperty = function () {\n          self.postMessage({\n            command: \'ABORT_GET\'\n          });\n        };\n        this.set = throttle(this._set, 16.666);\n        this.setProperties = throttle(this._setProperties, 16.666);\n      }\n      ContextProxy.prototype._setProperties = function(key, value) {\n        self.postMessage({\n          command: \'SETPROPS\',\n          params: [key, value]\n        });\n        return value;\n      }\n      ContextProxy.prototype._set = function(key, value) {\n        self.postMessage({\n          command: \'SET\',\n          params: [key, value]\n        });\n        return value;\n      };\n    ';
      return '\n      \'use strict\';\n      ' + throttle + '\n      ' + contextProxy + '\n      self.EmberContext = new ContextProxy();\n      self.onmessage = function(e) {\n        switch (e.data.command) {\n          case \'INVOKE\':\n            self.postMessage({\n              command: \'INVOKE\',\n              returns: (' + this._fn.toString() + ').apply(self.EmberContext, e.data.params)\n            });\n        } \n      };\n    ';
    },
    init: function init() {
      this._super.apply(this, arguments);
      (0, _emberMultithreadUtils._cleanupOnDestroy)(this.get('_context'), this, '_cleanupWorker');
    },
    _cleanupWorker: function _cleanupWorker() {
      var worker = this.get('_worker');
      if (worker) {
        worker.terminate();
        this.set('_worker', null);
      }
    },
    cancel: function cancel() {
      this._cleanupWorker();
    },
    perform: function perform() {
      var _this = this;

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        if (URL && Blob && Worker) {
          (function () {
            var blob = new Blob([_this._getWorkerScript()], { type: 'text/javascript' });
            var url = URL.createObjectURL(blob);
            var worker = new Worker(url);
            var context = _this.get('_context');
            _this.set('_worker', worker);
            worker.onmessage = function (e) {
              switch (e.data.command) {
                case 'INVOKE':
                  resolve(e.data.returns);
                  _this._cleanupWorker();
                  break;
                case 'ABORT_GET':
                  _ember['default'].assert('You cannot use `get`, `getProperties`, `getWithDefault`, `getEach`, `incrementProperty` or `decrementProperty` in ember-multithread workers', false);
                  break;
                case 'SET':
                  var _e$data$params = _slicedToArray(e.data.params, 2),
                      setKey = _e$data$params[0],
                      setValue = _e$data$params[1];

                  context.set(setKey, setValue);
                  break;
                case 'SETPROPS':
                  var _e$data$params2 = _slicedToArray(e.data.params, 2),
                      setPropsKey = _e$data$params2[0],
                      setPropsValue = _e$data$params2[1];

                  context.setProperties(setPropsKey, setPropsValue);
                  break;
              }
            };
            worker.onerror = function (e) {
              reject(e);
              _this._cleanupWorker();
            };
            worker.postMessage({
              command: 'INVOKE',
              params: args
            });
          })();
        } else {
          resolve(_this._fn.apply(_this, args));
        }
      });
    }
  }, _emberMultithreadUtils.INVOKE, function () {
    return this.perform.apply(this, arguments);
  }));
  function WorkerProperty() {
    for (var _len2 = arguments.length, decorators = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      decorators[_key2] = arguments[_key2];
    }

    var workerFn = decorators.pop();
    _emberMultithreadUtils._ComputedProperty.call(this, function () {
      return _Worker.create({
        _fn: workerFn,
        _context: this
      });
    });
  }

  WorkerProperty.prototype = Object.create(_emberMultithreadUtils._ComputedProperty.prototype);
});