define('ember-bootstrap-validations/components/bs-form', ['exports', 'ember', 'ember-bootstrap/components/bs-form'], function (exports, _ember, _emberBootstrapComponentsBsForm) {
  'use strict';

  var computed = _ember['default'].computed;

  exports['default'] = _emberBootstrapComponentsBsForm['default'].extend({

    hasValidator: computed.notEmpty('model.validate'),

    validate: function validate(model) {
      var m = model;

      if (model instanceof _ember['default'].ObjectProxy) {
        m = model.get('content');
      }

      _ember['default'].assert('Model needs to have the ember-validations mixin', m && typeof m.validate === 'function');
      return m.validate();
    }

  });
});