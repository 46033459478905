define('ember-bootstrap-validations/components/bs-form/element', ['exports', 'ember', 'ember-bootstrap/components/bs-form/element'], function (exports, _ember, _emberBootstrapComponentsBsFormElement) {
  'use strict';

  var computed = _ember['default'].computed;
  var defineProperty = _ember['default'].defineProperty;

  exports['default'] = _emberBootstrapComponentsBsFormElement['default'].extend({
    setupValidations: function setupValidations() {
      defineProperty(this, 'errors', computed.readOnly('model.errors.' + this.get('property')));
    }
  });
});