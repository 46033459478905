define('liquid-fire/transitions/wait', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = function (ms) {
    var _this = this;

    var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    return new _ember['default'].RSVP.Promise(function (resolve) {
      setTimeout(function () {
        resolve(_this.lookup(opts.then || 'default').call(_this));
      }, ms);
    });
  };
});