define('ember-multithread/utils', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports._cleanupOnDestroy = _cleanupOnDestroy;

  function _cleanupOnDestroy(owner, object, cleanupMethodName) {
    // TODO: find a non-mutate-y, hacky way of doing this.
    if (!owner.willDestroy.__ember_processes_destroyers__) {
      (function () {
        var oldWillDestroy = owner.willDestroy;
        var disposers = [];

        owner.willDestroy = function () {
          for (var i = 0, l = disposers.length; i < l; i++) {
            disposers[i]();
          }
          oldWillDestroy.apply(owner, arguments);
        };
        owner.willDestroy.__ember_processes_destroyers__ = disposers;
      })();
    }

    owner.willDestroy.__ember_processes_destroyers__.push(function () {
      object[cleanupMethodName]();
    });
  }

  var INVOKE = "__invoke_symbol__";

  exports.INVOKE = INVOKE;

  var locations = ['ember-glimmer/helpers/action', 'ember-routing-htmlbars/keywords/closure-action', 'ember-routing/keywords/closure-action'];

  for (var i = 0; i < locations.length; i++) {
    if (locations[i] in _ember['default'].__loader.registry) {
      exports.INVOKE = INVOKE = _ember['default'].__loader.require(locations[i])['INVOKE'];
      break;
    }
  }

  var _ComputedProperty = _ember['default'].__loader.require("ember-metal/computed").ComputedProperty;
  exports._ComputedProperty = _ComputedProperty;
});